import * as Sentry from "@sentry/gatsby";

Sentry.init({
  dsn: "https://02076df4faf74bc7438085e076e22b4f@o4504366448246784.ingest.us.sentry.io/4507233505574912",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 1.0,
});
