exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-document-js": () => import("./../../../src/pages/document.js" /* webpackChunkName: "component---src-pages-document-js" */),
  "component---src-pages-faculties-js": () => import("./../../../src/pages/faculties.js" /* webpackChunkName: "component---src-pages-faculties-js" */),
  "component---src-pages-fields-js": () => import("./../../../src/pages/fields.js" /* webpackChunkName: "component---src-pages-fields-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-templates-blog-article-js": () => import("./../../../src/templates/blog-article.js" /* webpackChunkName: "component---src-templates-blog-article-js" */),
  "component---src-templates-blog-tag-js": () => import("./../../../src/templates/blog-tag.js" /* webpackChunkName: "component---src-templates-blog-tag-js" */),
  "component---src-templates-document-js": () => import("./../../../src/templates/document.js" /* webpackChunkName: "component---src-templates-document-js" */),
  "component---src-templates-order-js": () => import("./../../../src/templates/order.js" /* webpackChunkName: "component---src-templates-order-js" */),
  "component---src-templates-study-field-js": () => import("./../../../src/templates/study-field.js" /* webpackChunkName: "component---src-templates-study-field-js" */)
}

